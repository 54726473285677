@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "DancingScript_Regular";
    src: url("../public/fonts/DancingScript_Regular.otf");
  }
@font-face {
    font-family: "QuicksandRegular";
    src: url("../public/fonts/Quicksand-Regular.otf");
  }
@font-face {
    font-family: "KaushanScript_Regular";
    src: url("../public/fonts/KaushanScript_Regular.otf");
  }
@font-face {
    font-family: "QuicksandLight";
    src: url("../public/fonts/Quicksand-Light.otf");
  }
  @font-face {
    font-family: "EBGaramond";
    src: url("../public/fonts/EBGaramond-VariableFont_wght.otf");
  }